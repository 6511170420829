<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader :text="nosologiaName">
        <EditButton label="Редактировать" />
        <DeleteNosologiaWithDialog
          :nosologiaName="nosologiaName"
          :nosologiaId="nosologiaId"
        />
      </PageHeader>

      <div class="mb-12">
        <TitleAndDataProfiles :profiles="profiles" />
        <TitleAndDataLine label="МКБ10" :value="nosologia.mkb10_codes" />
        <TitleAndDataLink label="Ссылка" :value="nosologia.cr_link" />
      </div>
    </div>
    <div v-else>Нет данных для этого заболевания (состояния)</div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'
import TitleAndDataLink from '@/components/common/TitleAndDataLink.vue'
import TitleAndDataProfiles from '@/components/common/TitleAndDataProfiles.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import DeleteNosologiaWithDialog from '@/components/buttons/DeleteNosologiaWithDialog'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_NOSOLOGIES } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю заболевание (состояние)',
    }
  },
  components: {
    DisplayLoading,
    DeleteNosologiaWithDialog,
    PageHeader,
    TitleAndDataLine,
    EditButton,
    TitleAndDataProfiles,
    TitleAndDataLink,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    loadUrl() {
      return `${API_NOSOLOGIES}/${this.nosologiaId}`
    },
    errorMessage() {
      return `Не удалось загрузить данные для заболевания (состояния): ${this.nosologiaId}`
    },
    nosologia() {
      return this.pageData
    },
    profiles() {
      return this.nosologia.profiles
    },
    nosologiaName() {
      return this.nosologia.name
    },
  },
}
</script>
