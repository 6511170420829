<template>
  <TitleAndDataLine :label="profilesLabel" :value="profilesValue" />
</template>

<script>
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'

export default {
  props: ['profiles'],
  components: {
    TitleAndDataLine,
  },
  computed: {
    profilesLabel() {
      return this.profiles.length > 1 ? 'Профили' : 'Профиль'
    },
    profilesValue() {
      return this.profiles.map(p => p.name).join(', ')
    },
  },
}
</script>
