<template>
  <div>
    <v-btn text small @click="onOpen"
      ><v-icon class="mr-1">mdi-delete</v-icon>Удалить</v-btn
    >

    <v-dialog v-model="dialog" max-width="600" @click:outside="onClose">
      <v-card>
        <v-card-title class="headline"
          >Удалить заболевание (состояние): {{ nosologiaName }}?</v-card-title
        >
        <v-card-text>
          <v-checkbox
            v-model="confirm"
            label="Я понимаю, что будут удалены все ответы экспертов по этому заболеванию (состоянию)"
            color="red"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="onDelete" :disabled="!confirm"
            >Удалить</v-btn
          >
          <v-btn @click="onClose">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { deleteNosologia } from '@/lib/requestsAPI'

import { sendFormWithToastAndRedirect } from '@/mixins/sendFormWithToastAndRedirect'
import { dialogMixin } from '@/mixins/dialogMixin'

import { ADMIN, NOSOLOGIES } from '@/store/const/path'

export default {
  props: ['nosologiaName', 'nosologiaId'],
  mixins: [dialogMixin, sendFormWithToastAndRedirect],
  methods: {
    onDelete() {
      this.sendFormWithToastAndRedirect({
        action: deleteNosologia,
        data: this.nosologiaId,
        successPath: `/${ADMIN}/${NOSOLOGIES}/`,
        successMessage: `${this.nosologiaName} удалена!`,
        errorMessage: `Не получилось удалить заболевание (состояние) ${this.nosologiaId}`,
      })
    },
  },
}
</script>

<style scoped>
.v-input--checkbox >>> .v-input__slot {
  align-items: flex-start !important;
}
</style>
